<template>
  <b-modal
    id="modal-add-one-link"
    centered
    :title="title"
    size="lg"
    :hide-footer="true"
  >
    <validation-observer ref="refFormObserver">
      <div>
        <!-- Row Loop -->
        <b-row ref="row">
          <!-- Item Name -->
          <b-col>
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="url"
              rules="url|required"
            >
              <h6>Link</h6>
              <b-form-group>
                <b-form-input
                  id="url"
                  v-model="value"
                  type="url"
                  :state="getValidationState(validationContext)"
                  placeholder="Enter webpage link"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Cost -->
          <b-col>
            <!-- Column Select -->
            <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <h6>Title</h6>
              <b-form-group>
                <b-form-input
                  id="matrix-1"
                  v-model="name"
                  :state="getValidationState(validationContext)"
                  placeholder="Enter webpage title"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="Link()"
          >
            Save
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hideAddLinkModal()"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
  import {
    BModal,
    // BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BFormInvalidFeedback,
    BRow,
    BCol,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { $themeConfig } from '@themeConfig'
  import Ripple from 'vue-ripple-directive'
  import { heightTransition } from '@core/mixins/ui/transition'

  export default {
    components: {
      BModal,
      BFormInput,
      // BForm,
      BFormGroup,
      BButton,
      BFormInvalidFeedback,
      BRow,
      BCol,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    mixins: [heightTransition],
    props: {
      title: {
        required: true,
        type: String,
      },
      doc: {
        type: Object,
        default: () => {},
      },
      link: { type: Function, default: () => '' },
    },
    data() {
      return {
        localLink: { ...this.doc },
        id: '',
        name: '',
        value: '',
      }
    },
    setup() {
      const { DropBoxIcon } = $themeConfig.icons
      return {
        DropBoxIcon,
      }
    },
    watch: {
      doc() {
        this.localLink = { ...this.doc }
        this.id = this.doc.id
        this.name = this.doc.name
        this.value = this.doc.value
      },
    },
    methods: {
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
      },
      hideAddLinkModal() {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-add-one-link')
        })
        this.resetForm()
      },
      Link() {
        const data = {
          id: this.localLink.id,
          name: this.name,
          value: this.value,
        }
        this.$emit('link', data)
        this.hideAddLinkModal()
      },
      resetForm() {
        this.$nextTick(() => {
          this.$refs.refFormObserver.reset()
        })
        // this.localLink = {}
      },
    },
  }
</script>

<style scoped>
  .repeater-form {
    overflow: hidden;
    transition: 0.35s height;
  }
  .logo {
    width: 14px;
    height: 14px;
    margin-right: 3.5px;
  }

  .add-btn {
    margin-right: 15px;
  }
</style>
