<template>
  <div class="frequency">
    <b-tabs nav-class="mb-1">
      <b-tab v-if="showTab('months')" title="Date or Day of Week">
        <b-list-group>
          <b-list-group-item
            v-for="month in months"
            :key="month.index"
            :style="
              !getDaysValue(frequency[month.value]) && isReadonly
                ? 'display:none!important'
                : 'border:1px solid rgba(34, 41, 47, 0.125)'
            "
            class="flex-column align-items-start cursor-pointer"
            @click="openFrequencyModal(month)"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-0">
                {{ month.title }}
              </h5>
              <button type="button" class="btn btn-icon p-0">
                <feather-icon icon="CalendarIcon" />
              </button>
            </div>
            <small
              v-if="
                frequency[month.value] && getDaysValue(frequency[month.value])
              "
              class="text-secondary pretty-frequency"
            >
              <div
                v-for="(weekValue, weekDay) in frequency[month.value]"
                :key="weekDay"
              >
                <div
                  v-if="weekDaysMap[weekDay] && weekValue && weekValue.length"
                >
                  <span>{{ weekDaysMap[weekDay] }}: </span>
                  <span>
                    <span v-for="(val, index) in daysAsc(weekValue)" :key="val">
                      <strong>{{ weeksDayValuesMap[val] || val }}</strong>
                      <span v-if="index < weekValue.length - 1">, </span>
                    </span>
                  </span>
                </div>
              </div>
            </small>
            <small v-else class="text-secondary">Not set</small>
          </b-list-group-item>
        </b-list-group>
      </b-tab>

      <span :style="isHidden()" class="my-danger"> No Schedule selected </span>
      <!-- <b-button
        variant="primary"
        :style="isHidden()"
        @click="edit()"
      >
        Add Schedule
      </b-button> -->

      <!--  Multiweek interval tab -->
      <b-tab
        v-if="showTab('Interval')"
        title="Multi-week interval"
        class="bottom-margin"
      >
        <b-card class="mb-5">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <!-- Form -->
            <b-form
              class="p-0 mb-5"
              @submit.prevent="handleSubmit(setIntervalFrequency)"
              @reset.prevent="resetForm"
            >
              <!-- Repeat Every -->
              <validation-provider
                #default="validationContext"
                rules="between:1,1000"
                name="Repeat Every"
              >
                <b-form-group v-if="isReadonly">
                  <label class="label-edit">Repeat Every (weeks)</label>
                  <b-form-input
                    id="repeat-every"
                    v-model.number="interval.RepeatEvery"
                    :disabled="isReadonly"
                    type="number"
                  />
                </b-form-group>
                <b-form-group v-else>
                  <label
for="repeat-every"
class="label-edit">Repeat Every (weeks)</label>
                  <b-form-input
                    id="repeat-every"
                    v-model.number="interval.RepeatEvery"
                    type="number"
                    :min="0"
                    name="RepeatEvery"
                    autofocus
                    :state="getValidationState(validationContext)"
                    @input="setIntervalFrequency"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Starts On -->
              <validation-provider
                #default="validationContext"
                name="Starts On"
              >
                <b-form-group>
                  <label for="starts-on" class="label-edit">Starts On</label>
                  <b-form-datepicker
                    id="starts-on"
                    v-model="interval.StartOn"
                    name="StartsOn"
                    class="mb-1"
                    :state="getValidationState(validationContext)"
                    :disabled="isReadonly"
                    @input="setIntervalFrequency"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Repeat On -->
              <validation-provider
                #default="validationContext"
                name="Repeat On"
              >
                <b-form-group :state="getValidationState(validationContext)">
                  <label for="repeat on" class="label-edit">Repeat On</label>
                  <v-select
                    v-model="interval.RepeatOn"
                    name="RepeatOn"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="days"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="repeat-on"
                    :disabled="isReadonly"
                    @input="setIntervalFrequency"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-button
                v-if="!isReadonly"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                @click="clearInterval()"
              >
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span class="align-middle">Clear</span>
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-tab>
    </b-tabs>

    <process-frequency-modal
      :month="selectedMonth"
      :value="value"
      :is-readonly="isReadonly"
      @save="setMonthFrequency"
    />
  </div>
</template>

<script>
  import {
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    BCard,
    BForm,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    BFormDatepicker,
    BButton,
  } from 'bootstrap-vue'

  import Ripple from 'vue-ripple-directive'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import vSelect from 'vue-select'
  import ProcessFrequencyModal from '@/views/process/ProcessFrequencyModal.vue'

  export default {
    components: {
      ProcessFrequencyModal,
      BTabs,
      BTab,
      BListGroup,
      BListGroupItem,
      BCard,
      BForm,
      BFormInvalidFeedback,
      BFormInput,
      BFormGroup,
      vSelect,
      BFormDatepicker,
      BButton,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    props: {
      frequency: {
        type: Object,
        default() {
          return {}
        },
      },
      isReadonly: {
        type: Boolean,
      },
      show: {
        type: Boolean,
      },
    },
    data() {
      return {
        months: [
          {
            title: 'January',
            value: 'Jan',
            index: 1,
          },
          {
            title: 'February',
            value: 'Feb',
            index: 2,
          },
          {
            title: 'March',
            value: 'Mar',
            index: 3,
          },
          {
            title: 'April',
            value: 'Apr',
            index: 4,
          },
          {
            title: 'May',
            value: 'May',
            index: 5,
          },
          {
            title: 'June',
            value: 'Jun',
            index: 6,
          },
          {
            title: 'July',
            value: 'Jul',
            index: 7,
          },
          {
            title: 'August',
            value: 'Aug',
            index: 8,
          },
          {
            title: 'September',
            value: 'Sep',
            index: 9,
          },
          {
            title: 'October',
            value: 'Oct',
            index: 10,
          },
          {
            title: 'November',
            value: 'Nov',
            index: 11,
          },
          {
            title: 'December',
            value: 'Dec',
            index: 12,
          },
        ],
        days: [
          { label: 'Sunday', value: 'sun' },
          { label: 'Monday', value: 'mon' },
          { label: 'Tuesday', value: 'tue' },
          { label: 'Wednesday', value: 'wed' },
          { label: 'Thursday', value: 'thu' },
          { label: 'Friday', value: 'fri' },
          { label: 'Saturday', value: 'sat' },
        ],
        selectedMonth: null,
        interval: { StartOn: new Date(Date.now()) },
        weekDaysMap: {
          sun: 'Sunday',
          mon: 'Monday',
          tue: 'Tuesday',
          wed: 'Wednesday',
          thu: 'Thursday',
          fri: 'Friday',
          sat: 'Saturday',
          days: 'Days',
        },
        weeksDayValuesMap: {
          '*': 'every',
          L: 'last',
        },
        a: true,
      }
    },
    computed: {
      value() {
        if (this.selectedMonth) {
          return this.frequency[this.selectedMonth.value]
        }
        return null
      },
    },
    watch: {
      frequency() {
        this.interval = { ...this.frequency.Interval }
      },
    },
    mounted() {
      this.interval = { ...this.frequency.Interval }
    },
    methods: {
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
      },
      openFrequencyModal(month) {
        if (!this.isReadonly) {
          this.selectedMonth = month
          this.$nextTick(() => {
            this.$bvModal.show('modal-frequency')
          })
        }
      },
      setMonthFrequency(value) {
        this.$set(this.frequency, this.selectedMonth.value, value)
      },
      setIntervalFrequency() {
        this.$set(this.frequency, 'Interval', this.interval)
      },
      isDateAssigned() {
        let state = false
        this.months.forEach(month => {
          const value = this.getDaysValue(this.frequency[month.value])
          if (value) {
            state = true
            return null
          }
          return null
        })
        return state
      },
      getDaysValue(days) {
        let value = false
        if (days) {
          const keys = Object.keys(days)
          keys.forEach(key => {
            if (days[key]?.length > 0) {
              value = true
            }
          })
        }
        return value
      },
      isHidden() {
        const style = 'display:none!important'
        if (
          (this.isDateAssigned() === true || this.frequency.Interval) &&
          this.isReadonly
        ) {
          return style
        }
        if (!this.isReadonly) {
          return style
        }
        return ''
      },
      daysAsc(day) {
        const arr = [...day]
        arr.sort((a, b) => (a > b) - (a < b))
        return arr
      },
      showTab(value) {
        const { Interval } = this.frequency
        if (!this.isReadonly) {
          return true
        }
        if (value === 'months') {
          if (this.isReadonly && this.isDateAssigned()) {
            return 'months'
          }
        }
        if (value === 'Interval') {
          if (this.isReadonly && Interval && Interval.RepeatEvery > 0) {
            return 'Interval'
          }
        }
        return false
      },
      edit() {
        this.$emit('edit')
      },
      clearInterval() {
        this.interval = {}
        this.setIntervalFrequency()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .pretty-frequency {
    display: block;
    margin-top: 6px;
  }
  .my-danger {
    color: #8a3f43 !important;
    text-align: center;
    margin: 5px;
  }
  .modal-dialog-centered {
    max-width: fit-content !important;
  }
  .bottom-margin {
    margin-bottom: 200px;
  }
</style>
