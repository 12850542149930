/* eslint-disable */
const { omit } = require('lodash')

const dayKeys = {
  mon: 'MO',
  tue: 'TU',
  wed: 'WE',
  thu: 'TH',
  fri: 'FR',
  sat: 'SA',
  sun: 'SU',
}

const monthIds = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
}

const lastDayOfMonthObject = {
  Jan: { days: [31] },
  Feb: { days: [28] },
  Mar: { days: [31] },
  Apr: { days: [30] },
  May: { days: [31] },
  Jun: { days: [30] },
  Jul: { days: [31] },
  Aug: { days: [31] },
  Sep: { days: [30] },
  Oct: { days: [31] },
  Nov: { days: [30] },
  Dec: { days: [31] },
}

// eslint-disable-next-line import/prefer-default-export
export const convertToRRule = frequency => {
  const interval = frequency.Interval
  let orderedFrequency = Object.keys(frequency)
    .sort((a, b) => monthIds[a] - monthIds[b])
    .reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = frequency[key]
      return obj
    }, {})

  let isLastDayOfEveryMonth = false
  if (
    JSON.stringify(lastDayOfMonthObject) ===
    JSON.stringify(omit(orderedFrequency, ['Interval', 'rrule']))
  ) {
    isLastDayOfEveryMonth = true
  }

  const rules = []
  const RRULE = 'RRULE:'
  const RR_FREQ = 'FREQ=MONTHLY;'
  const RR_COUNT = 'COUNT=30;'
  const RR_INTERVAL = 'INTERVAL=1;'
  const RR_WKST = 'WKST=MO;'

  const RR_SETPOS = 'BYSETPOS='
  const RR_BYDAY = 'BYDAY='
  const RR_BYMONTH = 'BYMONTH='
  const RR_BYMONTHDAY = 'BYMONTHDAY='

  const allDataForRules = {}

  let intervalRepeat
  let intervalStartDate
  let intervalRepeatDay
  const RR_DTSTART = 'DTSTART:'

  if (interval) {
    intervalRepeat = interval.RepeatEvery
    intervalStartDate = interval.StartOn
    intervalRepeatDay = interval.RepeatOn

    const intRule = `${RR_DTSTART}${intervalStartDate.replaceAll(
      '-',
      ''
    )}T000000Z RRULE:FREQ=WEEKLY;WKST=MO;INTERVAL=${intervalRepeat};BYDAY=${dayKeys[intervalRepeatDay]
      }`
    rules.push(intRule)
  }

  orderedFrequency = omit(orderedFrequency, ['Interval', 'rrule'])

  if (isLastDayOfEveryMonth) {
    rules.push('RRULE:FREQ=MONTHLY;COUNT=30;WKST=MO;BYMONTH=2;BYMONTHDAY=-1')
  } else {
    for (const month in orderedFrequency) {
      for (const day in orderedFrequency[month]) {
        if (orderedFrequency[month][day].length !== 0) {
          for (const d in orderedFrequency[month][day]) {
            if (allDataForRules[month] === undefined) {
              allDataForRules[month] = {
                [day]: [orderedFrequency[month][day][d]],
              }
            } else {
              if (allDataForRules[month][day] === undefined) {
                allDataForRules[month] = {
                  ...allDataForRules[month],
                  [day]: [orderedFrequency[month][day][d]],
                }
              } else {
                const td = []
                td.push(...allDataForRules[month][day])
                td.push(orderedFrequency[month][day][d])
                allDataForRules[month] = {
                  ...allDataForRules[month],
                  [day]: td,
                }
              }
            }
          }
        }
      }
    }

    if (Object.keys(allDataForRules).length) {
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const month in allDataForRules) {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const day in allDataForRules[month]) {
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const d in allDataForRules[month][day]) {
            if (allDataForRules[month][day][d] === '*') {
              const rule = `${RRULE}${RR_FREQ}${RR_COUNT}${RR_INTERVAL}${RR_WKST}${RR_BYMONTH}${monthIds[month]};${RR_BYDAY}${dayKeys[day]}`
              rules.push(rule)
            }

            if (allDataForRules[month][day][d] === 'L') {
              const rule = `${RRULE}${RR_FREQ}${RR_COUNT}${RR_INTERVAL}${RR_WKST}${RR_BYMONTH}${monthIds[month]};${RR_SETPOS}-1;${RR_BYDAY}${dayKeys[day]};${RR_BYMONTHDAY}-1,-2,-3,-4,-5,-6,-7,-8,-9,-10`
              rules.push(rule)
            }

            if (allDataForRules[month][day][d] === '1') {
              const rule = `${RRULE}${RR_FREQ}${RR_COUNT}${RR_INTERVAL}${RR_WKST}${RR_BYMONTH}${monthIds[month]};${RR_BYDAY}${dayKeys[day]};${RR_BYMONTHDAY}1,2,3,4,5,6,7`
              rules.push(rule)
            }

            if (allDataForRules[month][day][d] === '2') {
              const rule = `${RRULE}${RR_FREQ}${RR_COUNT}${RR_INTERVAL}${RR_WKST}${RR_BYMONTH}${monthIds[month]};${RR_BYDAY}${dayKeys[day]};${RR_BYMONTHDAY}8,9,10,11,12,13,14`
              rules.push(rule)
            }

            if (allDataForRules[month][day][d] === '3') {
              const rule = `${RRULE}${RR_FREQ}${RR_COUNT}${RR_INTERVAL}${RR_WKST}${RR_BYMONTH}${monthIds[month]};${RR_BYDAY}${dayKeys[day]};${RR_BYMONTHDAY}15,16,17,18,19,20,21`
              rules.push(rule)
            }

            if (allDataForRules[month][day][d] === '4') {
              const rule = `${RRULE}${RR_FREQ}${RR_COUNT}${RR_INTERVAL}${RR_WKST}${RR_BYMONTH}${monthIds[month]};${RR_BYDAY}${dayKeys[day]};${RR_BYMONTHDAY}22,23,24,25,26,27,28`
              rules.push(rule)
            }

            if (allDataForRules[month][day][d] === '5') {
              const rule = `${RRULE}${RR_FREQ}${RR_COUNT}${RR_INTERVAL}${RR_WKST}${RR_BYMONTH}${monthIds[month]};${RR_BYDAY}${dayKeys[day]};${RR_BYMONTHDAY}29,30,31`
              rules.push(rule)
            }
          }

          if (day === 'days') {
            const rule = `${RRULE}${RR_FREQ}${RR_COUNT}${RR_INTERVAL}${RR_WKST}${RR_BYMONTH}${monthIds[month]};${RR_BYMONTHDAY}${allDataForRules[month][day]}`
            rules.push(rule)
          }
        }
      }
    }
  }

  return rules
}
