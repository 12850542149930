<template>
  <b-modal
    id="modal-label"
    centered
    title="Create Label"
    size="sm"
    :hide-footer="true"
  >
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Label Name -->
        <validation-provider
          #default="validationContext"
          name="Label Name"
          rules="required"
        >
          <b-form-group label="Label Name" label-for="label">
            <b-form-input
              id="label"
              v-model="form.label"
              autofocus
              :state="getValidationState(validationContext)"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Add
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hideAddLabelModal()"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
  import {
    BModal,
    BForm,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    BButton,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      BModal,
      BForm,
      BFormInvalidFeedback,
      BFormInput,
      BFormGroup,
      BButton,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        form: this.getDefaultForm(),
      }
    },
    methods: {
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
      },
      hideAddLabelModal() {
        this.resetForm()
        this.$nextTick(() => {
          this.$bvModal.hide('modal-label')
        })
      },
      onSubmit() {
        const action = this.$store.dispatch('label/addLabel', this.form)
        action.then(() => {
          this.$bvModal.hide('modal-label')
          this.$emit('onAddedLabel', this.form)
          this.resetForm()
        })
      },
      resetForm() {
        this.$nextTick(() => {
          this.$refs.refFormObserver.reset()
        })
        this.form = this.getDefaultForm()
      },
      getDefaultForm() {
        return {
          label: '',
        }
      },
    },
  }
</script>
