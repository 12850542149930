<template>
  <div>
    <AddLinkModal
      entity="Link"
      title="Add Link"
      :link="setLink"
      :docs.sync="form.docs"
    />
    <AddOneLinkModal
      entity="Edit Link"
      title="Edit Link"
      :doc.sync="selectedDoc"
      @link="editLink"
    />
    <b-sidebar
      :id="'add-new-process-sidebar' && expanded ? 'mein-expanded-view' : ''"
      :visible="isSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      :no-close-on-backdrop="
        $refs.refFormObserver && $refs.refFormObserver.flags.dirty
      "
      no-header
      right
      @hidden="resetForm"
      @change="val => $emit('update:is-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="fixed-sidebar-header d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0 process-title">
            {{ title }}
          </h5>
          <div>
            <feather-icon
              v-if="isReadonly && $can('edit', 'process')"
              class="ml-1 cursor-pointer"
              icon="EditIcon"
              size="16"
              @click="editTab(value)"
            />
            <feather-icon
              :target="`expand-minimize-view`"
              class="ml-1 cursor-pointer"
              :icon="expanded ? 'MinimizeIcon' : 'MaximizeIcon'"
              size="16"
              @click="sideResize"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2 add-edit-sidebar-form"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Category -->
            <b-row>
              <b-col :md="expanded ? 6 : 12">
                <validation-provider
                  #default="validationContext"
                  name="Category"
                  :rules="form.id ? '' : 'required'"
                >
                  <b-form-group v-if="isReadonly">
                    <label class="label-edit">Category</label>
                    <b-list-group>
                      <b-list-group-item class="input-wrap">
                        {{
                          (form.cat.prefix ? form.cat.prefix + ': ' : ' ') +
                            (form.cat.description ? form.cat.description : ' ')
                        }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>
                  <b-form-group
                    v-if="!isReadonly"
                    :state="getValidationState(validationContext)"
                  >
                    <label
                      for="category"
                      class="label-edit first-required-field"
                    >Category (<span>*</span> <label>required</label> )</label>
                    <v-select
                      v-model="form.cat.id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="processCategoryOptions"
                      :reduce="val => val.value"
                      input-id="category"
                      class="wrapped-li test"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- ID -->
            <b-row>
              <b-col :md="expanded ? 6 : 12">
                <validation-provider
                  #default="validationContext"
                  name="ID"
                  rules="required"
                >
                  <b-form-group v-if="isReadonly">
                    <label class="label-edit">ID</label>
                    <b-list-group>
                      <b-list-group-item class="input-wrap">
                        {{ form.processHandle }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>
                  <b-form-group v-if="!isReadonly" class="first-required-field">
                    <label
for="id"
class="label-edit">Process ID (<span>*</span>
                      <label>required</label> )</label>
                    <b-form-input
                      id="id"
                      v-model="form.processHandle"
                      maxlength="255"
                      :state="getValidationState(validationContext)"
                      :plaintext="isReadonly"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Name -->
            <b-row>
              <b-col :md="expanded ? 6 : 12">
                <validation-provider
                  #default="validationContext"
                  name="Process Name"
                  rules="required"
                >
                  <b-form-group v-if="isReadonly">
                    <label class="label-edit">Process Name</label>
                    <b-list-group>
                      <b-list-group-item class="input-wrap">
                        {{ form.name }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>
                  <b-form-group v-if="!isReadonly" class="first-required-field">
                    <label
for="name"
class="label-edit">Process Name (<span>*</span>
                      <label>required</label> )</label>
                    <b-form-input
                      id="name"
                      v-model="form.name"
                      maxlength="255"
                      autofocus
                      :state="getValidationState(validationContext)"
                      :plaintext="isReadonly"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Description -->
            <b-row>
              <b-col md="12">
                <validation-provider name="Description">
                  <label class="label-edit">Description</label>
                  <Quill-Editor
                    :description.sync="form.description"
                    :is-readonly.sync="disabled"
                    @assign="changeDesc"
                  />
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col :md="expanded ? 6 : 12">
                <validation-provider>
                  <b-form-group v-if="!isReadonly">
                    <label for="Add Links" class="label-edit">Resources</label>
                    <b-button
                      variant="relief-primary"
                      class="btn-icon"
                      block
                      @click.prevent="openAddLinkModal()"
                    >
                      + Add New Resource
                    </b-button>
                    <b-list-group>
                      <b-list-group-item
                        v-if="!myDocs || myDocs.length === 0"
                        class="text-danger input-wrap"
                      >
                        Not Assigned
                      </b-list-group-item>
                      <b-list-group-item
                        v-for="l in myDocs"
                        v-else
                        :key="l.id"
                        class="input-wrap"
                        :href="l.value"
                        target="_blank"
                      >
                        <b-row>
                          <b-col md="8" class="centering item-wrap">
                            {{ l.name }}
                          </b-col>
                          <b-col md="2">
                            <b-button
                              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                              variant="outline-primary"
                              class="btn-icon rounded-circle deleteIcon-edit"
                              @click.prevent="openAddOneLinkModal(l)"
                            >
                              <feather-icon icon="EditIcon" />
                            </b-button>
                          </b-col>
                          <b-col md="2">
                            <b-button
                              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                              variant="outline-danger"
                              class="btn-icon rounded-circle deleteIcon-edit"
                              @click.prevent="removeDoc(l.id)"
                            >
                              <feather-icon icon="TrashIcon" />
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>
                  <b-form-group v-if="isReadonly">
                    <label for="Add Links" class="label-edit">Resources</label>
                    <b-list-group>
                      <b-list-group-item
                        v-if="!myDocs || myDocs.length === 0"
                        class="text-danger input-wrap"
                      >
                        Not Assigned
                      </b-list-group-item>
                      <b-list-group-item
                        v-for="l in myDocs"
                        v-else
                        :key="l.id"
                        class="input-wrap hyperlink-underlined cursor-pointer"
                        :href="l.value"
                        target="_blank"
                      >
                        {{ l.name }}
                        <feather-icon icon="ExternalLinkIcon" />
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Labels -->
            <b-row>
              <b-col :md="expanded ? 6 : 12">
                <validation-provider #default="validationContext" name="Labels">
                  <b-form-group v-if="isReadonly">
                    <label for="labels" class="label-edit">Labels</label>
                    <b-list-group>
                      <b-list-group-item
                        v-for="option in listGroupItems(
                          form.labelsIds,
                          labelOptions
                        )"
                        :key="option.value"
                        class="input-wrap"
                        :class="
                          option.label === 'Not Assigned' ? 'text-danger' : ''
                        "
                      >
                        {{ option.label }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>
                  <b-form-group
                    v-if="!isReadonly"
                    :state="getValidationState(validationContext)"
                  >
                    <label for="labels" class="label-edit">Labels</label>
                    <v-select
                      v-model="form.labelsIds"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="labelOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :disabled="isReadonly"
                      multiple
                      class="wrapped-li"
                      input-id="labels"
                      append-to-body
                      :calculate-position="withPopper"
                    >
                      <template #list-footer>
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-primary"
                          style="width: 100%"
                          @click="openCreateLabelModal()"
                        >
                          <feather-icon icon="PlusIcon" class="mr-50" />
                          <span class="align-middle">Create Label</span>
                        </b-button>
                      </template>
                    </v-select>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Owner Role -->
            <b-row>
              <b-col :md="expanded ? 6 : 12">
                <validation-provider
                  #default="validationContext"
                  rules="required"
                  name="Owner Role"
                >
                  <!-- Details -->
                  <b-form-group v-if="isReadonly" label="Owner Role">
                    <b-list-group
                      v-if="form.role"
                      class="hyperlink-underlined cursor-pointer"
                      @click="gotoRole(form.role)"
                    >
                      <b-list-group-item class="item-wrap">
                        {{ form.role.roleHandle }}
                      </b-list-group-item>
                    </b-list-group>
                    <b-list-group v-else class="text-danger">
                      <b-list-group-item> Not Assigned </b-list-group-item>
                    </b-list-group>
                  </b-form-group>

                  <!-- Form -->
                  <b-form-group
                    v-if="!isReadonly"
                    :state="getValidationState(validationContext)"
                  >
                    <label
                      for="owner-role"
                      class="label-edit first-required-field"
                    >Owner Role (<span>*</span>
                      <label>required</label> )</label>
                    <v-select
                      v-model="form.roleId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="roleOptions"
                      :reduce="val => val.value"
                      :clearable="true"
                      :disabled="isReadonly"
                      class="wrapped-li"
                      input-id="owner-role"
                      append-to-body
                      :calculate-position="withPopper"
                      @input="onChange"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Assigned Roles -->
            <b-row>
              <b-col :md="expanded ? 6 : 12">
                <validation-provider
                  #default="validationContext"
                  rules="required"
                  name="Assigned Roles"
                >
                  <!-- Details -->
                  <b-form-group v-if="isReadonly" label="Assigned Roles">
                    <b-list-group
                      v-if="
                        form.assignedRoles && form.assignedRoles.length !== 0
                      "
                    >
                      <b-list-group-item
                        v-for="option in form.assignedRoles"
                        :key="option.id"
                        class="hyperlink-underlined cursor-pointer item-wrap"
                        @click="gotoRole(option)"
                      >
                        {{ `${option.roleHandle} : ${option.name}` }}
                      </b-list-group-item>
                    </b-list-group>
                    <b-list-group v-else class="text-danger">
                      <b-list-group-item> Not Assigned </b-list-group-item>
                    </b-list-group>
                  </b-form-group>

                  <!-- Form -->
                  <b-form-group
                    v-if="!isReadonly"
                    :state="getValidationState(validationContext)"
                  >
                    <label
                      for="assigned-roles"
                      class="first-required-field label-edit"
                    >Assigned Roles (<span>*</span>
                      <label>required</label> )</label>
                    <v-select
                      v-model="form.assignedRolesIds"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="roleOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :disabled="isReadonly"
                      multiple=""
                      class="wrapped-li"
                      input-id="assigned-roles"
                      append-to-body
                      :calculate-position="withPopper"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Frequency Type-->
            <b-row>
              <b-col :md="expanded ? 6 : 12">
                <validation-provider
                  #default="validationContext"
                  name="Frequency"
                  rules="required"
                >
                  <b-form-group :state="getValidationState(validationContext)">
                    <label
for="frequency-type"
class="label-edit">Frequency</label>
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="form.frequencyType"
                      :options="frequencyTypeOptions"
                      text-field="label"
                      name="radio-options"
                      stacked
                      :disabled="isReadonly"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <process-frequency
              v-if="form.frequencyType === 'Scheduled'"
              :frequency.sync="form.frequency"
              :is-readonly="isReadonly"
              @edit="editTab(value)"
            />

            <!-- Form Actions -->
            <div
              class="mt-2 fixed-footer-form-actions"
              :class="{ 'd-flex': isReadonly || !isEditing }"
            >
              <div v-if="!isReadonly">
                <div v-if="isEditing" class="d-flex justify-content-between">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    @click="hide"
                  >
                    Cancel
                  </b-button>
                  <div>
                    <b-button
                      v-for="el in btns"
                      :key="el"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :variant="el === 'Save' ? 'outline-primary' : 'primary'"
                      :class="{ 'mr-2 ': el === 'Save' }"
                      type="submit"
                      :disabled="isLoading"
                      @click="submitState = el"
                    >
                      {{ el }}
                    </b-button>
                  </div>
                </div>
                <b-button
                  v-else
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="isLoading"
                >
                  Add
                </b-button>
              </div>
              <b-button
                v-if="isReadonly || !isEditing"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <label-add-modal @onAddedLabel="onLabelAdded" />
  </div>
</template>

<script>
  import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    // BFormTextarea,
    BCol,
    BRow,
    BFormInvalidFeedback,
    BButton,
    BListGroupItem,
    BListGroup,
    BFormRadioGroup,
    // BLink,
  } from 'bootstrap-vue'
  import { mapState, mapActions } from 'vuex'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, alphaNum, email } from '@validations'
  import { createPopper } from '@popperjs/core'
  import QuillEditor from '@/views/shared/QuillEditor.vue'
  import countries from '@/@fake-db/data/other/countries'
  import Enums from '@/utils/enums'
  import LabelAddModal from '@/views/label/LabelAddModal.vue'
  import AddLinkModal from '@/views/shared/AddLinkModal.vue'
  import AddOneLinkModal from '@/views/shared/AddOneLinkModal.vue'
  import ability from '@/libs/acl/ability'
  import { convertToRRule } from '@/utils/rrule-convertor'
  import ProcessFrequency from './ProcessFrequency.vue'

  export default {
    components: {
      ProcessFrequency,
      LabelAddModal,
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      AddOneLinkModal,
      // BFormTextarea,
      BCol,
      BRow,
      BFormInvalidFeedback,
      BButton,
      vSelect,
      BListGroupItem,
      BListGroup,
      BFormRadioGroup,
      AddLinkModal,
      QuillEditor,
      // BLink,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isSidebarActive',
      event: 'update:is-sidebar-active',
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true,
      },
      isReadonly: {
        type: Boolean,
      },
      value: {
        type: Object,
        default: () => this.getDefaultForm(),
      },
      editTab: {
        type: Function,
        default: () => this.editTab(this.value),
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
        form: this.getDefaultForm(),
        frequencyTypeOptions: Enums.frequencyTypeOptions,
        btns: ['Save', 'Save & Close'],
        submitState: '',
        links: [],
        dropbox: '',
        content: '',
        options: {
          modules: {
            toolbar: '#toolbar',
          },
          theme: 'snow',
        },
        disabled: this.isReadonly,
        expanded: false,
        selectedDoc: null,
        currentDocs: [],
        labelListOptions: [],
      }
    },
    computed: {
      ...mapState('process', ['process']),
      ...mapState('app', ['isLoading']),
      ...mapState('label', ['labels', 'allLabels']),
      ...mapState('role', ['roles', 'allRoles']),
      ...mapState('processCategory', ['processCategories']),
      isEditing() {
        return !!this.value
      },
      title() {
        if (this.isReadonly && this.value) {
          return `${this.value.name} Details`
        }
        if (this.value && this.value.name) {
          return `Edit ${this.value.name}`
        }
        return 'Add a New Process'
      },
      labelOptions() {
        return this.allLabels.nodes.map(item => ({
          label: item.label,
          value: item.id,
          ...item,
        }))
      },
      roleOptions() {
        // if (this.currentRouteName === 'my-process-list') {
        //   return this.currentUserRoles.nodes.map(item => ({
        //     label: item.name,
        //     value: item.id,
        //   }))
        // }
        return this.allRoles.nodes
          .map(item => ({
            label: `${item.roleHandle} : ${item.name}`,
            value: item.id,
            ...item,
          }))
          .sort((a, b) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          )
      },
      processCategoryOptions() {
        return this.processCategories.nodes.map(item => ({
          label: `${item.prefix}: ${item.description}`,
          value: item.id,
          ...item,
        }))
      },
      currentRouteName() {
        return this.$route.name
      },
      myDocs() {
        return this.currentDocs
      },
    },
    watch: {
      value(value) {
        if (value && Object.keys(value).length > 0) {
          this.form = {
            ...value,
            frequency: value.frequency || {},
            ...(value.labels
              ? {
                  labelsIds: value.labels.map(item => item.id),
                  labels: undefined,
                }
              : {}),
            ...(value.assignedRoles
              ? {
                  assignedRolesIds: value.assignedRoles.map(item => item.id),
                  assignedRoles: value.assignedRoles.sort((a, b) =>
                    a.roleHandle
                      .toLowerCase()
                      .localeCompare(b.roleHandle.toLowerCase())
                  ),
                }
              : {}),
            ...(value.role
              ? {
                  role: value.role,
                  roleId: value.role.id,
                }
              : {}),
            __typename: undefined,
          }
          this.currentDocs = [...value.docs]
        }
      },
      isReadonly() {
        this.disabled = this.isReadonly
      },
      process() {
        this.$emit('update:value', {
          ...this.form,
          ...this.value,
          ...this.process,
        })
      },
    },
    mounted() {
      if (ability.can('add', 'process') || ability.can('edit', 'process')) {
        this.fetchRolesIfNotLoaded()
      }
      this.fetchProcessCategoriesIfNotLoaded()
      this.fetchLabelsIfNotLoaded()
    },
    methods: {
      ...mapActions('label', ['getLabels', 'fetchLabelsIfNotLoaded']),
      ...mapActions('role', ['fetchRolesIfNotLoaded']),
      ...mapActions('processCategory', [
        'fetchProcessCategoriesIfNotLoaded',
        'getProcessCategories',
      ]),
      gotoRole(item) {
        const route = this.$router.resolve({
          path: '/roles',
          query: { q: item.roleHandle, qd: `id=${item.id}`, compact: true },
        })
        window.open(route.href, '_blank')
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
      },
      sideResize() {
        this.expanded = !this.expanded
      },
      onChange(value) {
        this.form.role = {
          ...this.form.role,
          id: value,
        }
        this.form.roleId = value
      },
      setLink(value) {
        this.currentDocs = [...this.currentDocs, ...value]
      },
      editLink(data) {
        const { id } = data
        const index = this.currentDocs.findIndex(el => el.id === id)
        this.currentDocs.splice(index, 1)
        this.currentDocs = [...this.currentDocs, data]
      },
      removeDoc(id) {
        this.$bvModal
          .msgBoxConfirm(
            'Are you sure that you want to delete this resource? ',
            {
              title: 'Please Confirm',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Delete',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then(value => {
            if (value) {
              const index = this.currentDocs.findIndex(el => el.id === id)
              this.currentDocs.splice(index, 1)
            }
          })
      },
      openAddLinkModal() {
        this.$nextTick(() => {
          this.$bvModal.show('modal-add-link')
        })
      },
      openAddOneLinkModal(item) {
        this.selectedDoc = item
        this.$nextTick(() => {
          this.$bvModal.show('modal-add-one-link')
        })
      },
      resetForm() {
        this.$emit('update:value', this.getDefaultForm())
        this.$nextTick(() => {
          this.$refs.refFormObserver.reset()
        })
        this.form = this.getDefaultForm()
      },
      changeDesc(value) {
        this.form.description = value
      },
      openCreateLabelModal() {
        this.$nextTick(() => {
          this.$bvModal.show('modal-label')
        })
      },
      async onLabelAdded() {
        await this.getLabels({
          offset: 0,
          limit: 1000,
        })
      },
      // updateProcessId() {
      //   const selectedCategory = this.processCategories.nodes.filter(x => x.id === this.form.categoryId)
      //   this.$set(this.form, 'processHandle', selectedCategory[0].prefix + String(selectedCategory[0].increment).padStart(3, '0'))
      // },
      onSubmit() {
        this.submitForm = { ...this.form }
        // console.log('frequency', JSON.stringify(this.submitForm.frequency))
        const rRules = convertToRRule(this.submitForm.frequency)
        // console.log('rRules', rRules)
        this.submitForm.frequency.rrule = rRules
        const { Interval } = this.submitForm.frequency
        if (Interval && Interval.RepeatOn) {
          this.submitForm.frequency.Interval.StartOn = this.formatDate(
            new Date(Date.now())
          )
        }
        this.submitForm.docs = this.currentDocs
        this.submitForm.role = this.form.roleId
        this.submitForm.categoryId = this.form.cat.id
        this.submitForm.cat = undefined
        this.submitForm.assignedRoles = undefined
        this.submitForm.roleId = undefined
        const action = this.submitForm.id
          ? this.$store.dispatch('process/updateProcess', this.submitForm)
          : this.$store.dispatch('process/addProcess', this.submitForm)

        action.then(() => {
          if (this.submitState === 'Save') {
            this.$emit('update:is-sidebar-active', true)
            this.$emit('update:is-readonly', true)
          } else {
            this.$emit('update:is-sidebar-active', false)
          }
          this.getProcessCategories({})
        })
      },
      formatDate(date) {
        let d = date
        const offset = d.getTimezoneOffset()
        d = new Date(d.getTime() - offset * 60 * 1000)
        return d.toISOString().split('T')[0]
      },
      getDefaultForm() {
        return {
          name: '',
          processHandle: '',
          description: '',
          frequencyType: Enums.frequencyTypeOptions[0].value,
          frequency: {},
          labelsIds: [],
          assignedRolesIds: [],
          role: null,
          categoryId: null,
          cat: {},
          docs: [],
        }
      },
      listGroupItems(ids, array) {
        if (ids && ids[0] !== null && ids.length > 0) {
          const items = ids.map(id => array.find(el => el.value === id))
          return items
        }
        const item = [{ label: 'Not Assigned', value: `${Math.random()}` }]
        return item
      },
      withPopper(dropdownList, component, { width }) {
        // eslint-disable-next-line no-param-reassign
        dropdownList.style.width = width

        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: 'bottom',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1],
              },
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn({ state }) {
                component.$el.classList.toggle(
                  'drop-up',
                  state.placement === 'top'
                )
              },
            },
          ],
        })

        return () => popper.destroy()
      },
    },
  }
</script>

<style lang="scss">
  #mein-expanded-view {
    width: 80% !important;
  }
</style>

<style lang="scss" scoped>
  @import '../../@core/scss/vue/libs/vue-select';

  #add-new-process-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }

  .text-area {
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    padding: 14px;
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .process-title {
    @extend .truncate;
    max-width: 70%;
  }

  .required-field {
    label::after {
      content: '*';
      color: #ea5455;
      font-weight: bold;
      font-size: larger;
      margin-left: 2px;
    }
  }

  .first-required-field {
    span {
      color: #ea5455;
      font-weight: bold;
      font-size: larger;
    }
  }

  .logo {
    width: 14px;
    height: 14px;
  }

  .repeater-form {
    overflow: hidden;
    transition: 0.35s height;
  }

  .fixed-sidebar-header {
    position: fixed;
    width: 100%;
    z-index: 10000;
  }

  .add-edit-sidebar-form {
    margin-top: 40px;
    margin-bottom: 52px;
    padding-top: 12px;
  }

  .fixed-footer-form-actions {
    position: fixed;
    bottom: 0;
    padding: 16px 58px 16px 0;
    width: 100%;
    background-color: white;
    z-index: 10000;
  }
  .input-wrap {
    overflow-wrap: break-word;
  }
  .centering {
    align-self: center !important;
  }
  .vs__selected {
    margin-top: 5px;
    font-weight: normal;
    text-transform: uppercase;
  }
</style>
